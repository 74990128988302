<template>
  <div class="bg-black-agro text-white pt-10 px-4 sm:px-0">
    <div class="container mx-auto">
      <div class="grid sm:grid-cols-2 gap-10">
        <div class="col text-sm">
          <img
            alt="agro-logo-footer"
            :src="require(`@/assets/LogoFooter.svg`)"
          />
          <div class="hidden sm:grid grid-cols-2 my-7">
            <div class="col">
              <div>Head Office:</div>
              <div>Ahmad Dahlan 53, Ponorogo, East Java, Indonesia</div>
            </div>
            <div class="col">
              <div>Factory:</div>
              <div>
                Raya Ponorogo - Madiun KM. 4, Ponorogo, East Java, Indonesia
              </div>
            </div>
          </div>
          <div class="hidden sm:block mb-3">
            <v-icon icon="mdi-phone" /> (0352) 3591094
          </div>
          <div class="hidden sm:block mb-3">
            <v-icon icon="mdi-email-outline" /> bizteam@agrofarm.id
          </div>
        </div>
        <div class="col hidden sm:block">
          <div class="grid grid-cols-1 gap-10">
            <div class="col text-right">
              <div class="grid grid-flow-row gap-2 text-white-agro">
                <div class="pb-2 border-b-2 border-gray-agro text-white">
                  Perusahaan
                </div>
                <div>
                  <router-link to="/about" class="cursor-pointer">
                    Tentang
                  </router-link>
                </div>
                <div>
                  <router-link to="/sustainbility" class="cursor-pointer">
                    Sustainability
                  </router-link>
                </div>
                <div>
                  <router-link to="/testimony" class="cursor-pointer">
                    Testimonial
                  </router-link>
                </div>
                <div>
                  <router-link to="/article" class="cursor-pointer">
                    Artikel
                  </router-link>
                </div>
                <div>
                  <router-link to="/career" class="cursor-pointer">
                    Karir
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block sm:hidden">
          <div>
            <router-link to="/about" class="cursor-pointer pb-4">
              Tentang
            </router-link>
          </div>
          <div>
            <router-link to="/sustainbility" class="cursor-pointer pb-4">
              Sustainability
            </router-link>
          </div>
          <div>
            <router-link to="/testimony" class="cursor-pointer pb-4 ">
              Testimonial
            </router-link>
          </div>
          <div>
            <router-link to="/article" class="cursor-pointer pb-4 ">
              Artikel
            </router-link>
          </div>
          <div>
            <router-link to="/career" class="cursor-pointer pb-4  ">
              Karir
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="border-t-2 border-gray-agro mt-10 p-4">
      <div class="container mx-auto block sm:flex items-center justify-between">
        <div
          class="flex justify-center sm:justify-normal sm:grid grid-flow-col gap-4"
        >
          <a
            href="https://www.linkedin.com/company/pt-agrofarm-nusa-raya/"
            target="_blank"
          >
            <v-icon icon="mdi-linkedin" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61552847044762&mibextid=kFxxJD"
            target="_blank"
          >
            <v-icon icon="mdi-facebook" />
          </a>
          <a href="https://www.instagram.com/agrofarmnusaraya/" target="_blank">
            <v-icon icon="mdi-instagram" />
          </a>
        </div>
        <div class="text-sm mt-2 sm:mt-0 text-center sm:text-left">
          © 2023 PT Agrofarm Nusa Raya. All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterLanding",
};
</script>
